import React, { FunctionComponent } from "react";

import { bemClassNames, useScrollPosition } from "../../utils";
import { categories } from "../../constants";
import { MenuItem } from "../MenuItem";
import "./Menu.scss";

const classNames = bemClassNames("menu");

export const Menu: FunctionComponent = () => {
	const scrollPosition = useScrollPosition();

	return (
		<nav className={classNames()}>
			<MenuItem
				large={!scrollPosition}
				title={categories.pages.title}
				shortTitle="Stránky"
				link="/"
			/>
			<MenuItem
				large={!scrollPosition}
				title={categories.contacts.title}
				shortTitle={categories.contacts.title}
				link="/kontakty"
			/>
		</nav>
	);
};
