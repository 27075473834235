import React, { FunctionComponent } from "react";

import "./Footer.scss";

export const Footer: FunctionComponent = () => (
	<div className="footer">
		<a className="footer__name" href="/kontakty">
			Pavel Pospíšil 2013–{(new Date()).getFullYear()}
		</a>
		<div className="footer__cookies">
			Stránka využívá soubory cookies pro měření návštěvnosti. Návštěvou stránky souhlasíte s jejich použitím.
		</div>
	</div>
);
