import React, { FunctionComponent, ComponentType } from "react";
import { transitions, positions, Provider, AlertComponentPropsWithStyle } from "react-alert"

import "./AlertProvider.scss";

const AlertTemplate: ComponentType<AlertComponentPropsWithStyle> = ({ message }) => (
    <div className="alert-provider__container">
        {message}
    </div>
);

export const AlertProvider: FunctionComponent = ({ children }) => (
    <Provider
        template={AlertTemplate}
        position={positions.BOTTOM_CENTER}
        timeout={3000}
        offset="30px"
        type="success"
        transition={transitions.FADE}
    >
        {children}
    </Provider>
);
