import React, { FunctionComponent, ReactNode } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useAlert } from "react-alert";

import { bemClassNames } from "../../utils";
import { ItemFooter } from "../ItemFooter";
import { ItemPreview } from "../ItemPreview";
import { ItemContainer } from "../ItemContainer";
import "./Item.scss";

export type ItemProps = {
	link?: string;
	title: string;
	description?: string;
	imageName: string;
	coming?: boolean;
	message?: string;
	errorMessage?: string;
	subdescription?: ReactNode;
	category: string;
}

const classNames = bemClassNames("item");

export const Item: FunctionComponent<ItemProps> = ({
	link,
	title,
	description,
	imageName,
	coming,
	message,
	errorMessage,
	subdescription,
	category,
}) => {
	const alert = useAlert();

	const handleOnCopy = (text: string, result: any) => {
		alert.show(result ? message : errorMessage);
	};

	const content = (
		<ItemContainer disabled={coming}>
			<ItemPreview
				alt={title}
				overlayText="Připravuji"
				activeOverlay={coming}
				{...{ imageName, category }}
			/>
			<ItemFooter {...{ title, description, subdescription }} />
		</ItemContainer>
	);

	if (link) {
		return (
			<a href={link} className={classNames({ disabled: !link && !message })}>
				{content}
			</a>
		);
	}

	if (coming) {
		return content;
	}

	return (
		<CopyToClipboard
			text={title}
			onCopy={handleOnCopy}
		>
			<div>
				{content}
			</div>
		</CopyToClipboard>
	);
};
