import React, { FunctionComponent, ReactNode } from "react"

import { bemClassNames } from "../../utils";
import "./CategoryComponent.scss";

export type CategoryComponentProps = {
	title: string,
	subtitle?: string | ReactNode,
	children?: any,
}

const classNames = bemClassNames("category-component");

export const CategoryComponent: FunctionComponent<CategoryComponentProps> = ({
	title,
	subtitle,
	children,
}) => (
	<div className={classNames()}>
		{title && (
			<div className={classNames("title")}>
				{title}
			</div>
		)}
		{subtitle && (
			<div className={classNames("subtitle")}>
				{subtitle}
			</div>
		)}
		<div className={classNames("list")}>
			{children}
		</div>
	</div>
);
