import { ReactNode } from "react";

export const UNBREAKABLE_SPACE = "\u00a0"

export const THIN_SPACE = " "

export type Page = {
	imageName: string;
	title: string;
	link?: string;
	description?: string;
	errorMessage?: string;
	message?: string;
	coming?: boolean;
	subdescription?: ReactNode;
}

export type Category = {
	name: string;
	title: string;
	subtitle?: string;
	pages: Page[];
}

type TypedObject<T> = { [key: string]: T };

export const categories: TypedObject<Category> = {
	pages: {
		name: "pages",
		title: "Webové stránky",
		subtitle: "Stránky, které jsem vytvořil a které spravuji",
		pages: [
			{
				imageName: "skalka",
				title: "Tábor Skalka",
				link: "https://tabor.tasov.cz",
				description: "III. turnus tábora Skalka",
			},
			{
				imageName: "znaky",
				title: "Speciální znaky",
				link: "https://znaky.pavelpo.cz/",
				description: "Užitečné speciální znaky a emoji",
			},
			{
				imageName: "pracejakonakostele",
				title: "Práce jako na kostele",
				link: "https://pracejakonakostele.cz/",
				description: "Arboristika a výškové práce",
			},
			{
				imageName: "raw",
				title: "Raw Workout",
				link: "https://raw.pavelpo.cz",
				description: "Členové Raw Workout Team",
			},
			{
				imageName: "bajdys",
				title: "Bajdyš Třebíč",
				link: "https://www.bajdys.cz",
				description: "Třebíčský folklorní soubor Bajdyš",
			},
			{
				imageName: "fytopatolog",
				title: "Fytopatolog",
				link: "https://fytopatolog.cz/",
				description: "Fytopatoložka Marie Bartíková",
			},
			{
				imageName: "pouproduction",
				title: "Pou Production",
				link: "https://www.pouproduction.com",
				description: "Vývoj mobilních aplikací",
			},
			{
				imageName: "ovce",
				title: "Chov ovcí",
				link: "https://ovce.tasov.cz",
				description: "Chov a prodej ovcí v obci Tasov",
			},
			{
				imageName: "dikys9pluslevouzadni",
				title: "Díky S9+ levou zadní",
				link: "http://dikys9pluslevouzadni.pavelpo.cz/",
				description: "Aplikace do soutěžě o Galaxy S9+",
			},
			{
				imageName: "fingerrace",
				title: "Závody Prstů",
				link: "https://fingerrace.pouproduction.com/",
				description: "Statistiky drobné mobilní hry",
			},
			{
				imageName: "diceroller",
				title: "Hrací kostky",
				link: "https://diceroller.pouproduction.com/",
				description: "Prezentace mobilní aplikace",
			},
			{
				imageName: "morokilometry",
				title: "MoroKilometry",
				link: "https://morokilometry.pavelpo.cz/",
				description: "Sportem k pomoci druhým",
			},
			{
				imageName: "navody",
				title: "Návody",
				link: "https://navody.pavelpo.cz/",
				description: "Řešení různých IT drobností",
			},
			{
				imageName: "hubnu",
				title: "Hubnu chůzí",
				link: "http://hubnu.pavelpo.cz",
				description: "Propojení chůze a hubnutí",
			},
		],
	},
	contacts: {
		name: "contacts",
		title: "Kontakty",
		subtitle: "U každé karty je uvedeno, zda kopíruje kontakt do schráky nebo otevírá odkaz",
		pages: [
			{
				imageName: "gmail",
				title: "info@pavelpo.cz",
				description: "Gmail",
				message: "E-mail byl zkopírován do schránky",
				errorMessage: "E-mail nebylo možné zkopírovat do schránky",
				subdescription: "Zkopírujte kliknutím",
			},
			{
				imageName: "facebook",
				title: "pavel.pospi1",
				description: "Facebook",
				link: "https://www.facebook.com/pavel.pospi1",
				subdescription: "Kliknutím otevřete",
			},
			{
				imageName: "whatsapp",
				title: "+420 605 382 129",
				description: "WhatsApp",
				message: "Telefonní číslo bylo zkopírováno do schránky",
				errorMessage: "Telefonní číslo nebylo možné zkopírovat do schránky",
				subdescription: "Zkopírujte kliknutím",
			},
			{
				imageName: "messenger",
				title: "pavel.pospi1",
				description: "Messenger",
				link: "https://m.me/pavel.pospi1",
				subdescription: "Kliknutím otevřete",
			},
			{
				imageName: "instagram",
				title: "@pavel_pospi",
				description: "Instagram",
				link: "https://www.instagram.com/pavel_pospi",
				subdescription: "Kliknutím otevřete",
			},
			{
				imageName: "linkedin",
				title: "Pavel Pospíšil",
				description: "LinkedIn",
				link: "https://www.linkedin.com/in/pavel-pospisil/",
				subdescription: "Kliknutím otevřete",
			},
			{
				imageName: "skype",
				title: "pavel.p.pospisil",
				description: "Skype",
				link: "https://join.skype.com/invite/bgLZ2YGbnmM9",
				subdescription: "Kliknutím otevřete",
			},
			{
				imageName: "web",
				title: "www.pavelpo.cz",
				description: "Webové stránka",
				link: "http://pavelpo.cz",
				subdescription: "Kliknutím otevřete",
			},
			{
				imageName: "ico",
				title: "02636328",
				description: "Identifikační číslo osoby",
				message: "Identifikační číslo osoby bylo zkopírováno do schránky",
				errorMessage: "Identifikační číslo osoby nebylo možné zkopírovat do schránky",
				subdescription: "Zkopírujte kliknutím",
			},
			{
				imageName: "bank",
				title: "670100-2212708632/6210",
				description: "Osobní bankovní účet",
				message: "Číslo osobního účtu bylo zkopírováno do schránky",
				errorMessage: "Číslo osobního účtu nebylo možné zkopírovat do schránky",
				subdescription: "Zkopírujte kliknutím",
			},
			{
				imageName: "bankwork",
				title: "670100-2220334562/6210",
				description: "Pracovní bankovní účet",
				message: "Číslo pracovního účtu bylo zkopírováno do schránky",
				errorMessage: "Číslo pracovního účtu nebylo možné zkopírovat do schránky",
				subdescription: "Zkopírujte kliknutím",
			},
			{
				imageName: "revolut",
				title: "@pavelpospi",
				description: "Revolut",
				link: "https://revolut.me/pavelpospi",
				subdescription: "Kliknutím otevřete aplikaci",
			},
			{
				imageName: "twitter",
				title: "@pavel_pospi",
				description: "Twitter",
				link: "https://twitter.com/pavel_pospi",
				subdescription: "Kliknutím otevřete",
			},
			{
				imageName: "youtube",
				title: "@pavel_pospi",
				description: "YouTube",
				link: "https://www.youtube.com/@pavel_pospi",
				subdescription: "Kliknutím otevřete",
			},
		],
	},
};
