import { useState } from "react";

import { withNaming } from "@bem-react/classname";

export const bemClassNames = withNaming({ e: "__", m: "--" });

export const removeAccent = (text: string) => text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const useScrollPosition = () => {
	const [position, setPosition] = useState(0);

	document.addEventListener("scroll", () =>
		setPosition(document.documentElement.scrollTop)
	);

	return position;
};
