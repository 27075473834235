import React, { FunctionComponent } from "react";

import { bemClassNames } from "../../utils";
import "./MenuItem.scss";

const classNames = bemClassNames("menu-item");

export type MenuItemProps = {
	title: string,
	link: string,
	large: boolean,
	shortTitle: string,
}

export const MenuItem: FunctionComponent<MenuItemProps> = ({ title, large, link, shortTitle }) => (
	<a
		key={title}
		className={classNames({ large })}
		href={link}
	>
		<span className={classNames("short-title")}>
			{shortTitle}
		</span>
		<span className={classNames("long-title")}>
			{title}
		</span>
	</a>
);
