import React, { FunctionComponent } from "react";

import { bemClassNames } from "../../utils";
import "./ItemContainer.scss";

export type ItemContainerProps = {
	children: any,
	disabled?: boolean,
}

const classNames = bemClassNames("item-container");

export const ItemContainer: FunctionComponent<ItemContainerProps> = ({
	children,
	disabled,
}) => (
	<div className={classNames({ disabled })}>
		{children}
	</div>
);
