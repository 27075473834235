import React, { FunctionComponent, ReactNode } from "react";

import { bemClassNames } from "../../utils";
import "./ItemFooter.scss";

const classNames = bemClassNames("item-footer");

export type ItemFooterProps = {
	title?: string;
	description?: string;
	subdescription?: ReactNode;
}

export const ItemFooter: FunctionComponent<ItemFooterProps> = ({
	title,
	description,
	subdescription,
}) => (
	<div className={classNames()}>
		{title && (
			<h1 className={classNames("title", { "no-margin": !description })}>
				{title.toLocaleUpperCase()}
			</h1>
		)}
		{description && (
			<h2 className={classNames("description")}>
				{description}
			</h2>
		)}
		{subdescription && (
			<h2 className={classNames("subdescription")}>
				{subdescription}
			</h2>
		)}
	</div>
);
