import React, { FunctionComponent, useState, useEffect } from "react";

import { bemClassNames } from "../../utils";
import { imageExists } from "./utils";
import "./ItemPreview.scss";

const classNames = bemClassNames("item-preview");

export type ItemPreviewProps = {
	imageName?: string;
	alt: string;
	activeOverlay?: boolean;
	overlayText?: string;
	noOverlayShadow?: boolean;
	category: string;
}

export enum Extension {
	jpg = "jpg",
	png = "png",
}

export const ItemPreview: FunctionComponent<ItemPreviewProps> = ({
	imageName,
	alt,
	activeOverlay,
	overlayText = "Připravuji",
	noOverlayShadow,
	category,
}) => {
	const [previewLoaded, setPreviewLoaded] = useState<boolean>(false);
	const [src, setSrc] = useState<string>();
	const originalFolder = "img/original/";
	const previewFolder = "img/preview/";

	useEffect(() => {
		const getImageSrc = (folder: string, imageName?: string, extension = Extension.jpg) => `${folder}${category}/${imageName}.${extension}`;

		if (previewLoaded) {
			imageExists(getImageSrc(originalFolder, imageName)).then((exists) => {
				if (exists) {
					setSrc(getImageSrc(originalFolder, imageName));
				} else {
					setSrc(getImageSrc(originalFolder, imageName, Extension.png));
				}
			});
		} else {
			imageExists(getImageSrc(previewFolder, imageName)).then((exists) => {
				if (exists) {
					setSrc(getImageSrc(previewFolder, imageName));
				} else {
					setSrc(getImageSrc(previewFolder, imageName, Extension.png));
				}
			});
		}
	}, [previewLoaded, imageName, category]);

	const handleImageLoad = () => {
		setPreviewLoaded(true);
	}

	return src ? (
		<div className={classNames()}>
			<div className={classNames("overlay", { active: activeOverlay, "no-overlay-shadow": noOverlayShadow })}>
				{overlayText}
			</div>
			{imageName && (
				<img
					className={classNames("image", { blured: activeOverlay })}
					onLoad={!previewLoaded ? handleImageLoad : undefined}
					{...{ src, alt }}
				/>
			)}
		</div>
	) : null;
};
