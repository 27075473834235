import React, { FunctionComponent } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Menu, Footer, AlertProvider, CategoryComponent, Item } from "./components";
import { categories, Category } from "./constants";
import "./reset.scss";
import "./main.scss";

const createCategory = ({ title, subtitle, pages, name }: Category) => (
    <CategoryComponent
        key={title}
        {...{ title, subtitle }}
    >
        {pages.map((page) => (
            <Item
                key={`${page.title} ${page.imageName}`}
                {...page}
                category={name}
            />
        ))}
    </CategoryComponent>
);

const Body: FunctionComponent = ({ children }) => (
    <div className="app">
        <Menu />
        {children}
        <Footer />
    </div>
);

const router = createBrowserRouter([
    { path: "/", element: <Body>{createCategory(categories.pages)}</Body>  },
    { path: "/kontakty", element: <Body>{createCategory(categories.contacts)}</Body> },
]);

export const Router = () => (
    <RouterProvider router={router} />
);

export const App: FunctionComponent = () => (
    <AlertProvider>
        <Router />
    </AlertProvider>
);
